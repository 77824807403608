'use client'

import { useState, lazy, Suspense, useRef, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/navigation'
import CallNotifications from './CallNotifications'
import PulseButton from './PulseButton'
import PhoneRingAnimation from './PhoneRingAnimation'
import FazierBadge from '../assets/fraizer_day.svg'
import Image from 'next/image'
import FazierBadgeWeek from '../assets/fraizer_week.svg'
import { usePassiveScrollListener, usePassiveTouchListener } from '../hooks/usePassiveEvents'

// Lazy load components that are not needed immediately
const CompanionProfiles = dynamic(() => import('./CompanionProfiles'), { 
  loading: () => <div className="min-h-[400px] bg-purple-900/50 animate-pulse rounded-lg" />,
  ssr: false 
})

const Testimonials = dynamic(() => import('./Testimonials'), {
  loading: () => <div className="min-h-[300px] bg-purple-900/50 animate-pulse rounded-lg" />,
  ssr: false
})

const OnboardingFlow = dynamic(() => import('./OnboardingFlow'), {
  ssr: false
})

// Optimize YouTube component with lazy loading and reduced JavaScript
const YouTubeEmbed = ({ videoId, title }: { videoId: string, title: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Intersection observer to load YouTube only when in viewport
  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoaded) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1, rootMargin: '100px' }
    );

    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isLoaded]);

  // Use passive event listener for touch events
  usePassiveTouchListener((e) => {
    // Handle touch events if needed
  }, containerRef.current);

  return (
    <div className="rounded-2xl overflow-hidden shadow-2xl bg-purple-900 p-2">
      <div 
        ref={containerRef}
        className="relative pb-[56.25%] h-0 bg-purple-800/50"
      >
        {isLoaded ? (
          <iframe
            className="absolute top-0 left-0 w-full h-full rounded-xl"
            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=0&controls=1&loop=0&modestbranding=1&rel=0`}
            title={title}
            loading="lazy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <div className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer" onClick={() => setIsLoaded(true)}>
            <div className="w-16 h-16 bg-purple-600 rounded-full flex items-center justify-center mb-2">
              <svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5V19L19 12L8 5Z" fill="currentColor" />
              </svg>
            </div>
            <div className="text-white text-center px-4">
              <h3 className="font-medium">{title}</h3>
              <p className="text-sm text-gray-300">Click to load video</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function LandingPage() {
  const [showOnboarding, setShowOnboarding] = useState(false)
  const [showAuthPopup, setShowAuthPopup] = useState(false)
  const router = useRouter()
  
  // Add scroll listener with passive option for better performance
  usePassiveScrollListener(() => {
    // Handle scroll events if needed
  });

  return (
    <div>
      <CallNotifications />
      <motion.button
        onClick={() => setShowAuthPopup(true)}
        className="fixed bottom-8 right-8 w-14 h-14 bg-gradient-to-r from-purple-500 to-purple-600 rounded-full shadow-lg flex items-center justify-center z-40 hover:scale-110 transition-transform duration-300"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        aria-label="Open authentication options"
      >
        <svg
          className="w-8 h-8 text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>
      </motion.button>
      <section id="home" className="pt-32 pb-16 text-center bg-purple-900">
        <div className="container mx-auto px-4">
          <div className="flex justify-center mb-8">
            <div 
              className="bg-gradient-to-r from-purple-600 via-purple-500 to-purple-600 px-6 py-3 rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300 border-2 border-purple-400/30 backdrop-blur-sm cursor-pointer"
              onClick={() => setShowAuthPopup(true)}
            >
              <div className="flex items-center gap-3">
                <div className="text-yellow-300 text-xl">🔥</div>
                <div>
                  <span className="text-white font-bold">Special Launch Offer:</span>
                  <span className="ml-2 text-white">Talk to your AI buddy for</span>
                  <span className="ml-2 text-yellow-300 font-bold text-xl">Free</span>
                </div>
                <div className="text-yellow-300 text-xl">⚡</div>
              </div>
            </div>
          </div>
          <PhoneRingAnimation 
           onClick={() => setShowAuthPopup(true)}
          />
          <h2 className="text-4xl font-bold mb-4">Welcome to CallYourBuddy</h2>
          <p className="text-xl mb-8">Click the button below, enter the details and call your AI buddy👇</p>
          <PulseButton
            onClick={() => setShowAuthPopup(true)}
            className="bg-purple-700 hover:bg-purple-900 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg"
            aria-label="Start call with AI companion"
          >
          Click to Start the call
          </PulseButton>
          <div className="mt-6 flex justify-center items-center gap-4 flex-wrap">
          <a 
              href="https://fazier.com/launches/callyourbuddy" 
              target="_blank"
              rel="noopener noreferrer"
              className="transform hover:scale-[1.02] transition-all duration-300"
            >
              <Image 
                src={FazierBadgeWeek}
                alt="CallYourBuddy - #1 Product of the week on Fazier"
                width={255}
                height={54}
                className="hover:opacity-95 transition-opacity"
              />
            </a>     
          </div>
        </div>
      </section>

      <Suspense fallback={<div className="min-h-[400px] bg-purple-900/50 animate-pulse rounded-lg" />}>
        <section id="companions">
          <CompanionProfiles />
        </section>
      </Suspense>

      {/* Video Demo Section - Optimized */}
      <section className="py-16 bg-purple-950">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-white mb-4">See CallYourBuddy in Action</h2>
            <p className="text-xl text-gray-300">Watch how our AI companions create meaningful conversations</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
            <YouTubeEmbed 
              videoId="q32Ium2kOKw"
              title="CallYourBuddy Demo"
            />
            <YouTubeEmbed 
              videoId="6Af3nJnpCHI"
              title="CallYourBuddy Features"
            />
          </div>
        </div>
      </section>

      <Suspense fallback={<div className="min-h-[300px] bg-purple-900/50 animate-pulse rounded-lg" />}>
        <section id="testimonials">
          <Testimonials />
        </section>
      </Suspense>

      <AnimatePresence>
        {showAuthPopup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ scale: 0.9, opacity: 0, y: 20 }}
              className="bg-purple-900 p-8 rounded-2xl shadow-2xl max-w-md w-full relative"
            >
              <motion.button
                onClick={() => setShowAuthPopup(false)}
                className="absolute -top-4 -right-4 bg-purple-700 hover:bg-purple-600 text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg border-2 border-purple-500 transition-all duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </motion.button>
              
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-white text-center mb-6">Welcome to CallYourBuddy</h2>
                
                <motion.button
                  onClick={() => {
                    setShowAuthPopup(false)
                    router.push('/sign-up')
                  }}
                  className="w-full bg-purple-600 hover:bg-purple-500 text-white font-semibold py-3 px-6 rounded-xl shadow-lg transition-colors duration-300"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Sign Up
                </motion.button>
                
                <motion.button
                  onClick={() => {
                    setShowAuthPopup(false)
                    router.push('/sign-in')
                  }}
                  className="w-full bg-purple-800 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-xl shadow-lg transition-colors duration-300"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Log In
                </motion.button>
                
                <p className="text-purple-300 text-center text-sm">
                  Choose an option to get started with your AI buddy
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showOnboarding && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-purple-900 p-6 rounded-2xl shadow-2xl max-w-md w-full relative"
            >
              <motion.button
                onClick={() => setShowOnboarding(false)}
                className="absolute -top-4 -right-4 bg-purple-700 hover:bg-purple-600 text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg border-2 border-purple-500 transition-all duration-300 hover:scale-110 hover:rotate-90"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </motion.button>
              <Suspense fallback={<div className="min-h-[400px] animate-pulse bg-purple-800 rounded-lg" />}>
                <OnboardingFlow 
                  isOpen={showOnboarding}
                  onClose={() => setShowOnboarding(false)} 
                />
              </Suspense>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}


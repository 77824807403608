'use client'

import { Phone } from 'lucide-react'
import MobileMenu from './MobileMenu'
import ScrollLink from './ScrollLink'
import Link from 'next/link'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useUser } from '@clerk/nextjs'
import { useRouter } from 'next/navigation'

const navLinks = [
  { href: '#home', label: 'Home' },
  { href: '#companions', label: 'Companions' },
  { href: '#testimonials', label: 'Testimonials' },
  { href: '/pricing', label: 'Pricing', isExternal: true },
  { href: '/blog', label: 'Blog', isExternal: true },
  { href: '/learn-more', label: 'Learn More', isExternal: true }
]

export default function Header() {
  const [mounted, setMounted] = useState(false)
  // Safely handle Clerk auth
  let isLoaded = false;
  let isSignedIn = false;
  
  try {
    const user = useUser();
    isLoaded = user.isLoaded || false;
    isSignedIn = user.isSignedIn || false;
  } catch (error) {
    console.log('Clerk provider not available in Header');
  }
  
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const handleSignIn = () => {
    setLoading(true)
    router.push('/sign-in')
  }

  if (!mounted) {
    return null
  }

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-purple-900 bg-opacity-50 backdrop-blur-md" style={{ fontFamily: 'Titillium Web' }}>
      <div className="container mx-auto px-4 py-3 flex items-center justify-between" suppressHydrationWarning={true}>
        <ScrollLink 
          href="#home" 
          className="flex items-center space-x-2 hover:opacity-80 transition-opacity"
        >
          <Image 
            src="/logo.png" 
            alt="CallYourBuddy Logo" 
            width={32} 
            height={32} 
            className="rounded-full"
            unoptimized
          />
          <h1 className="text-xl md:text-2xl font-bold text-white">CallYourBuddy</h1>
        </ScrollLink>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            {navLinks.map((link) => (
              <li key={link.href}>
                {link.isExternal ? (
                  <Link
                    href={link.href}
                    className="text-purple-200 hover:text-white transition-colors relative group"
                  >
                    {link.label}
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  </Link>
                ) : (
                  <ScrollLink
                    href={link.href}
                    className="text-purple-200 hover:text-white transition-colors relative group"
                  >
                    {link.label}
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  </ScrollLink>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex items-center space-x-2">
          {isLoaded && isSignedIn ? (
            <Link
              href="/dashboard?tab=pricing"
              className="bg-purple-600 hover:bg-purple-500 text-white px-4 py-2 rounded-lg transition-all shadow-md"
            >
              Dashboard
            </Link>
          ) : (
            <button
              onClick={handleSignIn}
              className="bg-purple-600 hover:bg-purple-500 text-white px-4 py-2 rounded-lg transition-all shadow-md"
            >
              Sign In/Sign Up
            </button>
          )}
        </div>
        <MobileMenu navLinks={navLinks} />
      </div>
    </header>
  )
}


'use client'

import { motion } from 'framer-motion'
import { ReactNode } from 'react'

interface PulseButtonProps {
  children: ReactNode
  onClick: () => void
  className?: string
}

export default function PulseButton({ children, onClick, className = '' }: PulseButtonProps) {
  return (
    <div className="relative inline-block">
      {/* Pulsing background */}
      <motion.div
        className="absolute inset-0 rounded-full bg-purple-500/30"
        animate={{
          scale: [1, 1.15, 1],
          opacity: [0.7, 0.3, 0.7]
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      
      {/* Button */}
      <motion.button
        className={`relative z-10 ${className}`}
        onClick={onClick}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 17
        }}
      >
        {children}
      </motion.button>
    </div>
  )
} 
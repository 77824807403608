'use client'

import { motion } from 'framer-motion'

interface PhoneRingAnimationProps {
  onClick: () => void
}

export default function PhoneRingAnimation({ onClick }: PhoneRingAnimationProps) {
  return (
    <div className="flex justify-center mb-10">
      <motion.div
        className="relative cursor-pointer"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
      >
        {/* Outer ripple */}
        <motion.div
          className="absolute inset-0 rounded-full bg-purple-500 opacity-20"
          animate={{
            scale: [1, 1.5, 1.8],
            opacity: [0.5, 0.3, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop",
          }}
        />
        
        {/* Middle ripple */}
        <motion.div
          className="absolute inset-0 rounded-full bg-purple-500 opacity-20"
          animate={{
            scale: [1, 1.3, 1.6],
            opacity: [0.5, 0.3, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "loop",
            delay: 0.3,
          }}
        />
        
        {/* Phone container */}
        <motion.div
          className="relative w-32 h-32 bg-purple-600 rounded-full flex items-center justify-center z-10 shadow-xl border-4 border-purple-400/50"
          animate={{ rotate: [0, 5, -5, 0] }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatDelay: 3,
          }}
        >
          {/* Phone icon */}
          <svg
            className="w-16 h-16 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
        </motion.div>
      </motion.div>
    </div>
  )
} 
// Custom image loader for Next.js
export default function myImageLoader({ src, width, quality }) {
  // For local images in the /images directory, we can return them directly
  if (src.startsWith('/images/')) {
    return src;
  }

  // For Clerk images, use their CDN
  if (src.includes('clerk.com') || src.includes('clerk.dev')) {
    return src;
  }

  // Default case: just return the src unchanged
  return src;
} 
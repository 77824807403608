'use client'

import { useEffect } from 'react'
import Link from 'next/link'

interface ScrollLinkProps {
  href: string
  children: React.ReactNode
  className?: string
  onClick?: () => void
}

export default function ScrollLink({ href, children, className, onClick }: ScrollLinkProps) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const link = target.closest('a')
      if (!link || !link.hash) return

      e.preventDefault()
      const element = document.getElementById(link.hash.slice(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        if (onClick) onClick()
      }
    }

    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [onClick])

  return (
    <Link href={href} className={className}>
      {children}
    </Link>
  )
} 
'use client'

import { useState } from 'react'
import { Menu, X } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import ScrollLink from './ScrollLink'
import { useUser } from '@clerk/nextjs'
import { useRouter } from 'next/navigation'
import Link from 'next/link'

interface MobileMenuProps {
  navLinks: Array<{ href: string; label: string; isExternal?: boolean }>
}

export default function MobileMenu({ navLinks }: MobileMenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { isLoaded, isSignedIn } = useUser()
  const router = useRouter()

  const handleSignIn = () => {
    setIsOpen(false)
    router.push('/sign-in')
  }

  return (
    <div className="md:hidden" suppressHydrationWarning={true}>
      <button
        className="text-white"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
      >
        {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="absolute left-0 right-0 bg-purple-800 z-50"
            suppressHydrationWarning={true}
          >
            <nav className="container mx-auto px-4 py-4">
              <ul className="space-y-3">
                {navLinks.map((link) => (
                  <li key={link.href}>
                    {link.isExternal ? (
                      <Link
                        href={link.href}
                        className="block text-purple-200 hover:text-white transition-colors py-2 px-4 rounded-lg hover:bg-purple-700"
                        onClick={() => setIsOpen(false)}
                      >
                        {link.label}
                      </Link>
                    ) : (
                      <ScrollLink
                        href={link.href}
                        className="block text-purple-200 hover:text-white transition-colors py-2 px-4 rounded-lg hover:bg-purple-700"
                        onClick={() => setIsOpen(false)}
                      >
                        {link.label}
                      </ScrollLink>
                    )}
                  </li>
                ))}
                <li className="pt-2 border-t border-purple-700">
                  {isLoaded && isSignedIn ? (
                    <Link
                      href="/dashboard?tab=pricing"
                      className="block text-white font-bold bg-purple-600 hover:bg-purple-500 py-2 px-4 rounded-lg transition-colors"
                      onClick={() => setIsOpen(false)}
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <button
                      onClick={handleSignIn}
                      className="w-full text-left text-white font-bold bg-purple-600 hover:bg-purple-500 py-2 px-4 rounded-lg transition-colors"
                    >
                      Sign In
                    </button>
                  )}
                </li>
              </ul>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
} 
import { motion } from 'framer-motion'

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large'
  color?: 'purple' | 'indigo' | 'gradient'
  showText?: boolean
}

export default function LoadingSpinner({ 
  size = 'medium', 
  color = 'gradient',
  showText = false
}: LoadingSpinnerProps) {
  const sizeClasses = {
    small: 'h-8 w-8',
    medium: 'h-12 w-12',
    large: 'h-16 w-16'
  }
  
  const colorClasses = {
    purple: 'border-t-purple-500 border-r-purple-500/30 border-b-purple-500/10 border-l-purple-500/30',
    indigo: 'border-t-indigo-500 border-r-indigo-500/30 border-b-indigo-500/10 border-l-indigo-500/30',
    gradient: 'border-t-purple-500 border-r-indigo-500 border-b-purple-300 border-l-indigo-300'
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <motion.div 
        initial={{ opacity: 0, scale: 0.8, rotate: 0 }}
        animate={{ 
          opacity: 1, 
          scale: 1,
          rotate: 360,
          transition: { 
            opacity: { duration: 0.3 }, 
            scale: { duration: 0.3 }, 
            rotate: { duration: 1, repeat: Infinity, ease: "linear" } 
          }
        }}
        className={`rounded-full ${sizeClasses[size]} border-4 ${colorClasses[color]} shadow-lg`}
      />
      
      {showText && (
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-4 text-sm text-purple-500 font-medium"
        >
          Loading...
        </motion.p>
      )}
    </div>
  )
} 
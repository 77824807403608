'use client'

import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

interface Notification {
  id: number
  name: string
  country: string
  time: string
}

export default function CallNotifications() {
  const [notifications, setNotifications] = useState<Notification[]>([])
  
  // Generate random notifications
  useEffect(() => {
    const names = [
      'Emma', 'Noah', 'Olivia', 'Liam', 'Ava', 'William', 'Sophia', 'Mason',
      'Isabella', 'James', 'Mia', 'Benjamin', 'Charlotte', 'Jacob', 'Amelia'
    ]
    
    const countries = [
      'United States', 'Canada', 'United Kingdom', 'Australia', 'Germany',
      'France', 'Spain', 'Italy', 'Japan', 'Brazil', 'Mexico', 'India'
    ]
    
    // Function to generate a random notification
    const generateNotification = (): Notification => {
      const randomName = names[Math.floor(Math.random() * names.length)]
      const randomCountry = countries[Math.floor(Math.random() * countries.length)]
      
      // Random time between 1-10 minutes ago
      const randomMinutes = Math.floor(Math.random() * 10) + 1
      const timeText = `${randomMinutes} min ago`
      
      return {
        id: Date.now(),
        name: randomName,
        country: randomCountry,
        time: timeText
      }
    }
    
    // Show a notification every 5-10 seconds
    const interval = setInterval(() => {
      const newNotification = generateNotification()
      setNotifications(prev => [newNotification, ...prev.slice(0, 2)]) // Keep only the latest 3
      
      // Remove the notification after 5 seconds
      setTimeout(() => {
        setNotifications(prev => prev.filter(n => n.id !== newNotification.id))
      }, 5000)
    }, Math.random() * 5000 + 5000)
    
    return () => clearInterval(interval)
  }, [])
  
  return (
    <div className="fixed bottom-5 left-5 z-40 space-y-4 max-w-xs w-full">
      <AnimatePresence>
        {notifications.map(notification => (
          <motion.div
            key={notification.id}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            className="bg-purple-800/90 backdrop-blur-sm rounded-lg shadow-lg border border-purple-700 p-4 flex items-start gap-3"
          >
            <div className="w-10 h-10 bg-purple-600 rounded-full flex items-center justify-center flex-shrink-0">
              <span className="text-white font-bold">{notification.name.charAt(0)}</span>
            </div>
            <div className="flex-grow">
              <p className="text-white font-medium">{notification.name} from {notification.country}</p>
              <p className="text-purple-300 text-sm">Just called an AI companion {notification.time}</p>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}


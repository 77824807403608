import { useEffect, useRef } from 'react';

// Utility hook to attach passive event listeners
export function usePassiveEventListener(
  eventType: string,
  handler: EventListener,
  element: HTMLElement | Window | Document | null = null,
  options: AddEventListenerOptions = { passive: true }
): void {
  const savedHandler = useRef<EventListener>();
  const { passive, capture, once } = options;

  // Update ref.current value if handler changes
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const targetElement = element || window;
    const eventListener: EventListener = (event) => savedHandler.current?.(event);
    const opts = { passive, capture, once };

    targetElement.addEventListener(eventType, eventListener, opts);

    return () => {
      targetElement.removeEventListener(eventType, eventListener, { capture });
    };
  }, [eventType, element, passive, capture, once]);
}

// Export a simpler version for just scroll events
export function usePassiveScrollListener(
  handler: EventListener,
  element: HTMLElement | Window | Document | null = null
): void {
  usePassiveEventListener('scroll', handler, element);
}

// Export a simpler version for just touch events
export function usePassiveTouchListener(
  handler: EventListener,
  element: HTMLElement | Window | Document | null = null
): void {
  usePassiveEventListener('touchstart', handler, element);
  usePassiveEventListener('touchmove', handler, element);
  usePassiveEventListener('touchend', handler, element);
}

// Export a simpler version for just wheel events
export function usePassiveWheelListener(
  handler: EventListener,
  element: HTMLElement | Window | Document | null = null
): void {
  usePassiveEventListener('wheel', handler, element);
} 
'use client'

import { useState, useEffect } from 'react'
import { useUser } from '@clerk/nextjs'
import { useRouter } from 'next/navigation'
import LoadingSpinner from './components/LoadingSpinner'
import Header from './components/Header'
import Footer from './components/Footer'
import LandingPage from './components/LandingPage'

export default function HomePage() {
  // Wrap the Clerk hooks in try/catch to handle cases where ClerkProvider isn't available
  let isLoaded = false;
  let isSignedIn = false;
  
  try {
    const user = useUser();
    isLoaded = user.isLoaded || false;
    isSignedIn = user.isSignedIn || false;
  } catch (error) {
    // If Clerk isn't initialized yet, we'll handle it in useEffect
    console.log('Clerk provider not available yet');
  }
  
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    if (isLoaded && isSignedIn) {
      router.push('/dashboard')
    }
  }, [isLoaded, isSignedIn, router])
  
  const handleSignIn = () => {
    setLoading(true)
    router.push('/sign-in')
  }
  
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-950 via-purple-900 to-indigo-950 text-white">
      <Header />
      <LandingPage />
      <Footer />
    </div>
  )
}


'use client'

import Link from 'next/link'
import Image from 'next/image'

const companionLinks = [
  {
    name: "Luna",
    role: "Night-time Companion",
    image: "/images/luna.webp",
    link: "/companions/luna"
  },
  {
    name: "Emma",
    role: "Empathy Specialist",
    image: "/images/emma.webp",
    link: "/companions/emma"
  },
  {
    name: "James",
    role: "Mindfulness Coach",
    image: "/images/james.webp",
    link: "/companions/james"
  },
  {
    name: "Max",
    role: "Motivation Expert",
    image: "/images/max.webp",
    link: "/companions/max"
  }
];

const helpLinks = [
  { name: "How It Works", href: "/how-it-works" },
  { name: "FAQ", href: "/faq" },
  { name: "Pricing", href: "/pricing" },
  { name: "Terms of Service", href: "/terms" },
  { name: "Privacy Policy", href: "/privacy" },
  { name: "Contact Us", href: "mailto:contact@callyourbuddy.com" }
];

const useCaseLinks = [
  { name: "Lonely Nights", href: "/use-cases/lonely-nights" },
  { name: "Mental Wellness", href: "/use-cases/mental-wellness" },
  { name: "Daily Companion", href: "/use-cases/daily-companion" }
];

const comparisonLinks = [
  { name: "vs ChatGPT", href: "/compare/chatgpt-alternative" },
  { name: "vs Replika", href: "/compare/replika-alternative" },
  { name: "Why Choose Us", href: "/blog/why-we-excel" }
];

export default function Footer() {
  return (
    <footer className="bg-purple-950 border-t border-purple-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {/* Help & Support */}
          <div>
            <h3 className="text-lg font-bold text-white mb-4">Help & Support</h3>
            <ul className="space-y-2">
              {helpLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    href={link.href}
                    className="relative group text-purple-200 hover:text-white transition-colors block"
                  >
                    {link.name}
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Use Cases */}
          <div>
            <h3 className="text-lg font-bold text-white mb-4">Use Cases</h3>
            <ul className="space-y-2">
              {useCaseLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    href={link.href}
                    className="relative group text-purple-200 hover:text-white transition-colors block"
                  >
                    {link.name}
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Comparisons */}
          <div>
            <h3 className="text-lg font-bold text-white mb-4">Comparisons</h3>
            <ul className="space-y-2">
              {comparisonLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    href={link.href}
                    className="relative group text-purple-200 hover:text-white transition-colors block"
                  >
                    {link.name}
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Our Companions */}
          <div>
            <h3 className="text-lg font-bold text-white mb-4">Our Companions</h3>
            <div className="grid grid-cols-2 gap-4">
              {companionLinks.map((companion) => (
                <Link
                  key={companion.name}
                  href={companion.link}
                  className="relative group flex items-center space-x-2 text-purple-200 hover:text-white transition-colors"
                >
                  <Image
                    src={companion.image}
                    alt={companion.name}
                    width={32}
                    height={32}
                    className="rounded-full"
                  />
                  <div>
                    <div className="font-medium">{companion.name}</div>
                    <div className="text-xs text-gray-400">{companion.role}</div>
                  </div>
                  <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-purple-900 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm mb-4 md:mb-0">
              © 2025 CallYourBuddy. All rights reserved.
            </div>
            <div className="flex space-x-6">
              <Link
                href="/"
                className="relative group text-purple-200 hover:text-white transition-colors"
              >
                Start Chatting
                <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
              </Link>
              <Link
                href="/blog"
                className="relative group text-purple-200 hover:text-white transition-colors"
              >
                Blog
                <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
              </Link>
              <Link
                href="/learn-more"
                className="relative group text-purple-200 hover:text-white transition-colors"
              >
                Learn More
                <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

